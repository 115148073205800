import { FormMetadata, FormProvider, getFormProps } from "@conform-to/react";
import { FetcherWithComponents, Form as RemixForm } from "@remix-run/react";
import { ComponentProps, ReactNode } from "react";
import { Button } from "./Button";
import { Error } from "./Error";
import { Input } from "./Input";
import { Label } from "./Label";
import { Select } from "./Select";
import { Status } from "./Status";
import { Switch } from "./Switch";
import { Textarea } from "./Textarea";
import { Turnstile } from "./Turnstile";

type Props = ComponentProps<typeof RemixForm> & {
  form: FormMetadata<any, string[]>;
  fetcher?: FetcherWithComponents<any>;
  children: ReactNode;
};

export function Form({ form, fetcher, children, ...props }: Props) {
  return (
    <FormProvider context={form.context}>
      {fetcher ? (
        <fetcher.Form {...getFormProps(form)} {...props}>
          {children}
        </fetcher.Form>
      ) : (
        <RemixForm {...getFormProps(form)} {...props}>
          {children}
        </RemixForm>
      )}
    </FormProvider>
  );
}

Form.Button = Button;
Form.Error = Error;
Form.Input = Input;
Form.Label = Label;
Form.Select = Select;
Form.Status = Status;
Form.Switch = Switch;
Form.Textarea = Textarea;
Form.Turnstile = Turnstile;
