import { FieldMetadata } from "@conform-to/react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = ComponentProps<"div"> & {
  meta: FieldMetadata;
  className?: string;
};

export function Error({ meta, className, ...props }: Props) {
  if (!meta.errors) return null;

  return (
    <div className={twMerge("mt-1 text-sm text-red-600", className)} {...props}>
      {meta.errors}
    </div>
  );
}
