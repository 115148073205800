import { ComponentProps, ReactNode } from "react";
import { CgSpinnerTwo } from "react-icons/cg";
import { twMerge } from "tailwind-merge";

type Variant =
  | "primary"
  | "secondary"
  | "link"
  | "light"
  | "dark"
  | "outline"
  | "default";

type Props = ComponentProps<"button"> & {
  children: ReactNode;
  value?: string;
  variant?: Variant;
  className?: string;
  isLoading?: boolean;
};

export function Button({
  value,
  variant = "default",
  className,
  isLoading = false,
  children,
  ...props
}: Props) {
  switch (variant) {
    case "primary":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm",
        "bg-primary-background text-primary-text hover:bg-primary-background/90",
        className,
      );

      break;
    case "secondary":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm",
        "bg-secondary-background text-secondary-text hover:bg-secondary-background/90",
        className,
      );

      break;
    case "light":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm",
        "bg-neutral-200 text-neutral-900 hover:bg-neutral-200/80",
        className,
      );

      break;
    case "dark":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm",
        "bg-neutral-800 text-white hover:bg-neutral-800/90",
        className,
      );

      break;
    case "outline":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium border rounded-lg text-sm",
        "border-primary-background text-primary-background hover:bg-primary-background hover:border-primary-background hover:text-primary-text",
        className,
      );

      break;
    case "default":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium border rounded-lg text-sm",
        "bg-white border-neutral-200 text-neutral-900 hover:bg-neutral-50",
        className,
      );

      break;
    case "link":
      className = twMerge(
        "font-medium focus:underline hover:underline underline-offset-4",
        "text-blue-500 hover:text-blue-400",
        className,
      );

      break;
  }

  return (
    <button
      type="submit"
      name="action"
      value={value}
      disabled={isLoading}
      className={twMerge(className, "disabled:opacity-75 transition-all")}
      {...props}
    >
      {isLoading ? <CgSpinnerTwo className="w-5 h-5 animate-spin" /> : children}
    </button>
  );
}
