import { useForm } from "@conform-to/react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = ComponentProps<"div">;

export function Status({ className, ...props }: Props) {
  const [form] = useForm({});

  if (!form.errors) return null;

  return (
    <div
      className={twMerge(
        "px-3 py-2 text-sm font-medium text-red-800 bg-red-50 rounded-lg",
        className,
      )}
      {...props}
    >
      {form.errors}
    </div>
  );
}
