import { FieldMetadata, useInputControl } from "@conform-to/react";
import { Switch as BaseSwitch } from "@headlessui/react";

type Props = {
  meta: FieldMetadata;
};

export function Switch({ meta }: Props) {
  const field = useInputControl(meta as any);

  return (
    <BaseSwitch
      checked={field.value === "on"}
      onChange={() => field.change(field.value === "on" ? "off" : "on")}
      value="on"
      className="relative inline-flex w-12 h-6 p-1 data-[checked]:bg-primary-background rounded-full group border-5 bg-neutral-200"
    >
      <span className="transition-all bg-white rounded-full shadow size-4 inlin-block group-data-[checked]:translate-x-6" />
    </BaseSwitch>
  );
}
