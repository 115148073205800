import {
  FieldMetadata,
  getInputProps,
  useInputControl,
} from "@conform-to/react";
import { ElementRef, useEffect, useRef } from "react";

declare global {
  var turnstile: any;
}

type Props = {
  meta: FieldMetadata;
  sitekey: string;
};

// https://developers.cloudflare.com/turnstile/
export function Turnstile({ meta, sitekey }: Props) {
  const field = useInputControl(meta as any);
  const element = useRef<ElementRef<"div">>(null);

  useEffect(() => {
    if (typeof turnstile == "undefined") return;

    turnstile.render(element.current, {
      sitekey,
      callback: (token: string) => {
        field.change(token);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitekey]);

  return (
    <>
      <input
        value={field.value}
        onChange={(e) => field.change(e.target.value)}
        onFocus={field.focus}
        onBlur={field.blur}
        {...getInputProps(meta, { type: "hidden" })}
      />
      <div ref={element} className="flex justify-center"></div>
    </>
  );
}
