import { FieldMetadata } from "@conform-to/react";
import { ComponentProps, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = ComponentProps<"label"> & {
  meta: FieldMetadata;
  children: ReactNode;
  required?: boolean;
  className?: string;
};

export function Label({
  meta,
  children,
  className,
  required = false,
  ...props
}: Props) {
  return (
    <label
      htmlFor={meta.id}
      className={twMerge(
        "flex gap-1 mb-1 text-sm font-medium text-neutral-700",
        className,
      )}
      {...props}
    >
      {children}
      {required && <span className="text-red-600">*</span>}
    </label>
  );
}
