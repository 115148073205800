import { FieldMetadata, getSelectProps } from "@conform-to/react";
import { ComponentProps, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = ComponentProps<"select"> & {
  meta: FieldMetadata;
  children: ReactNode;
  className?: string;
};

export function Select({ meta, children, className, ...props }: Props) {
  return (
    <select
      className={twMerge(
        "block w-full border border-neutral-200 rounded-lg disabled:bg-neutral-50 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
        className,
      )}
      {...getSelectProps(meta)}
      {...props}
    >
      {children}
    </select>
  );
}
