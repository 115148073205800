import {
  FieldMetadata,
  getInputProps,
  useInputControl,
} from "@conform-to/react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = Omit<ComponentProps<"input">, "type"> & {
  meta: FieldMetadata;
  type?: any;
  className?: string;
};

export function Input({ meta, type = "text", className, ...props }: Props) {
  const field = useInputControl(meta as any);

  return (
    <input
      value={field.value}
      onChange={(e) => field.change(e.target.value)}
      onFocus={field.focus}
      onBlur={field.blur}
      className={twMerge(
        "block w-full border border-neutral-200 rounded-lg disabled:bg-neutral-50 sm:text-sm",
        className,
      )}
      {...getInputProps(meta, { type })}
      {...props}
    />
  );
}
