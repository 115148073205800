import {
  FieldMetadata,
  getTextareaProps,
  useInputControl,
} from "@conform-to/react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = Omit<ComponentProps<"textarea">, "type"> & {
  meta: FieldMetadata;
  className?: string;
};

export function Textarea({ meta, className, ...props }: Props) {
  const field = useInputControl(meta as any);

  return (
    <textarea
      value={field.value}
      onChange={(e) => field.change(e.target.value)}
      onFocus={field.focus}
      onBlur={field.blur}
      className={twMerge(
        "block w-full border border-neutral-200 rounded-lg disabled:bg-neutral-50 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
        className,
      )}
      {...getTextareaProps(meta)}
      {...props}
    />
  );
}
